import * as React from 'react';
import NavbarToggler from 'reactstrap/lib/NavbarToggler';
import Collapse from 'reactstrap/lib/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCalendarAlt, faSignOutAlt, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import { history } from '../../src/HelperMethods';
import { ApplicationState } from '../store';
import * as GlobalStore from '../store/Global';
import { connect } from 'react-redux';
// import './NavMenu.css';

type GlobalProps = GlobalStore.GlobalState
    & typeof GlobalStore.actionCreators
    & RouteComponentProps<{}>;

export class NavMenu extends React.PureComponent<GlobalProps, { isOpen: boolean }> {
    _documentRef;
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
    }

    public render() {
        return (
            <header id="headerMenu" ref={(c) => this._documentRef = c}>
                <div className="container-fluid" id="navigationMenu">
                    <div className="row gradient">
                        <div className="col-2  text-center d-none d-lg-block">
                            <a className="navbar-brand" href="">
                                <img src={require("../assets/images/ringo_logo.png")} className="" alt="logo" style={{ width: "118px" }} />
                            </a>
                        </div>

                        <div className="col-12 col-lg-10">
                            <nav className="navbar navbar-expand-lg navbar-dark mb-0">
                                <div className="d-lg-none" style={{ display: this.state.isOpen ? 'block' : 'none' }}>
                                    <ul className="navbar-nav flex-row align-items-center">
                                        <div className="mt-0 navbar-user-crcl mr-2"><span className="txt-clr-blue ">{this.props.LoginUserName.charAt(0)}</span>
                                        </div>
                                        <li>
                                            <a className="nav-link text-white font-weight-normal font-medium" href="">{this.props.LoginUserName + ", " + this.props.UserClientName}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <NavbarToggler onClick={this.toggle} className={"mr-2"} />
                                <Collapse className="flex-sm-row-reverse" id="navBarCollapseElem" isOpen={this.state.isOpen} navbar>

                                    <ul className="navbar-nav mr-auto">
                                        <li className="nav-item bg-menu-white border-menu-hr nav-link">
                                            <a className="nav-link d-block d-lg-none grainsboro-clr" href="">
                                                <FontAwesomeIcon icon={faHome} className="ml-3 active-icon" /> <span className="ml-3 navText">Home</span>
                                            </a>

                                            <a className="nav-link d-none d-lg-block grainsboro-clr mt-3" href="">Home</a>
                                        </li>

                                        <div className="dropdown nav-link active-menu border-menu-hr d-block d-md-none">
                                            {/* <a href="" className="text-white text-decoration-none">Timesheet Admin</a> */}
                                            <UncontrolledDropdown nav inNavbar>
                                                <DropdownToggle nav caret className={"navText"}>
                                                    <FontAwesomeIcon icon={faCalendarAlt} className="ml-3 d-lg-none active-icon" />
                                                    <span className={"nav-span"}>
                                                        Timesheet Admin
                                                    </span>

                                                </DropdownToggle>
                                                <DropdownMenu className="text-decoration-none timesheetMenu dropdown-menu-mobile" right>
                                                    <DropdownItem className="active-icon bg-active-sub-menu border-bottom" tag={Link} to="/ApproveTimesheet">
                                                        <span className="ml-4">Submitted Timesheets</span>
                                                    </DropdownItem>
                                                    {/* <DropdownItem className="border-bottom">
                                                    <span className="mt-1">Non-Submitted Timesheets</span>
                                                        </DropdownItem> */}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>

                                        <div className="dropdown nav-link dashboard-bl active-menu  border-menu-hr d-none d-md-block">
                                            {/* <a href="" className="text-white text-decoration-none">Timesheet Admin</a> */}
                                            <UncontrolledDropdown nav inNavbar>
                                                <DropdownToggle nav caret className={"navText"}>
                                                    <FontAwesomeIcon icon={faCalendarAlt} className="ml-3 d-lg-none active-icon" />
                                                    <span className={"nav-span nav-span-ipad"}>
                                                        <span className="navText ml-3 ml-lg-0">Timesheet Admin</span>
                                                    </span>

                                                </DropdownToggle>
                                                <DropdownMenu className="text-decoration-none timesheetMenu dropdown-menu-mobile dropdown-menu-right-submit" right>
                                                    <DropdownItem className="active-icon bg-active-sub-menu border-bottom" tag={Link} to="/ApproveTimesheet">
                                                        <span className="ml-5 ml-lg-1">Submitted Timesheets</span>
                                                    </DropdownItem>
                                                    {/* <DropdownItem className="border-bottom">
                                                    <span className="mt-1">Non-Submitted Timesheets</span>
                                                        </DropdownItem> */}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>

                                        {/* <li className="nav-item bg-menu-white border-menu-hr">
                                            <a className="nav-link d-block d-lg-none grainsboro-clr" href="">
                                                <FontAwesomeIcon icon={faUserCog} className="ml-3" /> <span className="ml-3">Job Admin</span>
                                            </a>
                                            <a className="nav-link d-none d-lg-block grainsboro-clr mt-1" href="">Jobs Admin</a>
                                        </li> */}

                                        <li className="nav-item nav-item-ipad">
                                            <a className="nav-link d-block d-lg-none text-dark menu-logo" href="">
                                                <img src={require("../assets/images/ringo_login.png")} className="" alt="logo" style={{ width: "118px" }} />
                                            </a>
                                        </li>

                                    </ul>
                                </Collapse>
                                <div className="d-lg-none text-center nav-title-mobile" style={{ display: this.state.isOpen ? 'none' : 'block', width: "65%" }}>
                                    <span className="font-weight-bold text-white d-block " id="curPageTitle">{this.props.CurrentPageName}</span>
                                    <span className="font-weight-bold text-white d-block font-exrtasmall ">{this.props.UserClientName}</span>
                                </div>
                                <div className="d-lg-none mb-2" style={{ display: this.state.isOpen ? 'none' : 'block', width: "10%" }}>
                                    <a href="javascript:void(0)" onClick={this.logoutUser}><FontAwesomeIcon icon={faSignOutAlt} className="text-white ml-3 lead float-right signOut" /></a>
                                </div>
                                <div className="d-none d-lg-block col-auto">
                                    <ul className="navbar-nav">
                                        <div className="navbar-user-crcl"><span className="text-center txt-clr-blue">{this.props.LoginUserName.charAt(0).toUpperCase()}</span></div>
                                        <div>
                                            <UncontrolledDropdown nav inNavbar>
                                                <DropdownToggle nav caret className={"text-white ml-2 font-medium"}>
                                                    {this.props.LoginUserName}
                                                </DropdownToggle>
                                                <DropdownMenu className="text-decoration-none" right>
                                                    <DropdownItem>
                                                        <a href="javascript:void(0)" onClick={this.logoutUser}>Logout</a>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            <span className="text-white ml-3 font-medium">{this.props.UserClientName}</span>
                                        </div>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>

            </header>
        );
    }


    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    private handleDocumentClick(e) {
        const container = this._documentRef;
        if (e.target !== container && !container.contains(e.target)) {
            //this.toggle();
            var navCollapseElem = document.getElementById("navBarCollapseElem");
            if(navCollapseElem){
                var navCollapseCls = navCollapseElem.classList;
                if(navCollapseCls.contains('show')){
                    this.toggle();
                }
            }
        }
    }

    public componentWillMount() {
        document.addEventListener('click', this.handleDocumentClick, true);
    }

    public componentWillUnmount(){
        document.removeEventListener('click', this.handleDocumentClick, true);
    }
    

    private logoutUser = () => {
        localStorage.removeItem('user');
        history.push('/Login');
    }
}

export default connect(
    (state: ApplicationState) => state.global, // Selects which state properties are merged into the component's props
    GlobalStore.actionCreators // Selects which action creators are merged into the component's props
)(NavMenu as any);