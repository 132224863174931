import React from "react";
import { IProviderContactState } from "./IProviderContactState";
import { authHeader, formatter } from "../../HelperMethods";
import ShimmerTab from "../Shared/ShimmerTab";


export class ProviderContact extends React.PureComponent<{ location: any }, IProviderContactState> {
    private previousState = this.props.location.state;
    constructor(props) {
        super(props);
        this.state = {
            providerContracts: [],
            dataLoaded: false
        };
    }

    public render() {
        return (
            <div>
                <div className="col-12 pl-0 pr-0 mt-2">

                    <div className="row ml-0 mr-0 d-block d-md-none d-flex">
                        <div className="col-12 col-md-10 mx-auto text-left pl-0 pr-0">
                            <div className="row justify-content-start ">
                                <div className="col-6 mx-auto pr-0">
                                    <span className="mobileBlock">Provider:</span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.reportee}</span>
                                </div>
                                <div className="col-6 text-right pl-0">
                                    <span className="mobileBlock">Position: </span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.position}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row ml-0 mr-0 d-block d-md-none d-flex mt-1">
                        <div className="col-12 col-md-10 mx-auto text-left pl-0 pr-0">
                            <div className="row justify-content-start ">
                                <div className="col-6 mx-auto pr-0">
                                    <span className="mobileBlock">Pay Period:</span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.durationStart + " - " + this.previousState.durationEnd}</span>
                                </div>
                                <div className="col-6 text-right pl-0">
                                    <span className="mobileBlock">Division:</span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.division}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ml-0 mr-0 d-block d-md-none d-flex mt-1">
                        <div className="col-12 col-md-10 mx-auto text-left pl-0 pr-0">
                            <div className="row justify-content-start ">
                                <div className="col-6 mx-auto pr-0">
                                    <span className="mobileBlock">Dept/Location:</span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.deptLocation}</span>
                                </div>
                                <div className="col-6 text-right pl-0">
                                    <span className="mobileBlock">Vendor:</span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.vendor}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="d-none d-md-block mt-1 font-medium">
                    <div className="row ml-0 mr-0 d-none d-md-block d-flex mt-1">
                        <div className="col-md-12 mx-auto text-left pl-0 pr-0">
                            <div className="row justify-content-start ">
                                <div className="col-6 mx-auto pr-0">
                                    <span className="mobileBlock">Provider: </span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.reportee}</span>
                                </div>
                                <div className="col-6 text-right pl-0">
                                    <span className="mobileBlock">Position: </span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.position}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row ml-0 mr-0 d-none d-md-block d-flex mt-1">
                        <div className="col-md-12 mx-auto text-left pl-0 pr-0">
                            <div className="row justify-content-start ">
                                <div className="col-6 mx-auto pr-0">
                                    <span className="mobileBlock">Assignment Date: </span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.durationStart + " - " + this.previousState.durationEnd}</span>
                                </div>
                                <div className="col-6 text-right pl-0">
                                    <span className="mobileBlock">Division: </span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.division}</span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row ml-0 mr-0 d-none d-md-block d-flex mt-1">

                        <div className="col-md-12 mx-auto text-left pl-0 pr-0">
                            <div className="row justify-content-start ">
                                <div className="col-6 mx-auto pr-0">
                                    <span className="mobileBlock">Dept/Location: </span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.deptLocation}</span>
                                </div>
                                <div className="col-6 text-right pl-0">
                                    <span className="mobileBlock">Vendor: </span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.vendor}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row mb-1">
                    <div className="col-12">
                        <hr />
                    </div>
                </div>
                <div className="col-12 mx-auto text-center mt-1">
                    <div style={{ display: this.state.dataLoaded ? "none" : "block" }}><ShimmerTab /></div>
                </div>
                <div className="row mt-0 " style={{ display: this.state.dataLoaded ? "block" : "none" }}>
                    <div className="col-12">
                        <div className="shadow">
                            <div className="table-responsive">
                                <table className="custom-tbl table table-striped">
                                    <thead>
                                        <tr className="th">
                                            <th>Service Type</th>
                                            <th>Bill Type </th>
                                            <th className="text-right">Bill Rate</th>
                                            <th className="text-right">H Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.providerContracts.length > 0 ?
                                            this.state.providerContracts.map((contract, index) => (
                                                <tr className="card-ts-bg-green" key={index}>
                                                    <td className="txt-clr-blue border-top-0 font-weight-bold">{contract.serviceType}</td>
                                                    <td className="border-top-0">{contract.billType}</td>
                                                    <td className="border-top-0 text-right">{formatter.format(contract.billRate)}</td>
                                                    <td className="border-top-0 text-right">{formatter.format(contract.holidayRate)}</td>
                                                </tr>
                                            )) :
                                            <tr className="text-center">
                                                <td colSpan={4}>No data to display</td>
                                            </tr>

                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        var pageTitleElem = document.getElementById("curPageTitle");
        pageTitleElem.innerText = "Provider Contract";
    }

    public async componentWillMount() {
        this.getProviderContracts();
    }

    public getProviderContracts() {
        var requestBody = {
            JobId:  this.previousState.jobId,
            RequestId: this.previousState.requestId
        };

        var requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
        };

        fetch(`/api/Timesheet/GetProviderContracts`, requestOptions).then(function (response) {
            return response.json();
        })
        .then(detailData => {
            this.setState({
                providerContracts: detailData,
                dataLoaded: true
            });
            // console.log(detailData);
        },
        error =>{
            this.setState({
                dataLoaded:true
            });
        });
    }
};