import React from "react";
import { IApproveTimesheetState, IDropdownState, PositionState, TimesheetExcelState } from "./IApproveTimesheetState";
import "@progress/kendo-theme-default/dist/all.css";
import { filterBy } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { PositionItemTemplate } from "./PositionItemTemplate";
import "../../assets/css/CustomStyles.css";
import "../../assets/css/KendoCustom.css";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { history, authHeader, logout, GlobalProps } from "../../HelperMethods";
import "../ApproveTimesheet/ApproveTimesheet.css";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as GlobalStore from "../../store/Global";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import ShimmerTab from "../Shared/ShimmerTab";
import { isBrowser } from "react-device-detect";
import ApproveTimesheetShimmer from "../Shared/ApproveTimesheetShimmer";

export interface IStaticModel {
    vendorList?: Array<IDropdownState>;
    industryList?: Array<IDropdownState>;
    divisionList?: Array<IDropdownState>;
    departmentList?: Array<IDropdownState>;
}

const loadingPanel = (
    <div className="k-loading-mask">
        <span className="k-loading-text">Loading</span>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
    </div>
);

export class ApproveTimesheet extends React.Component<GlobalProps, IApproveTimesheetState> {
    private staticDropdownObj: IStaticModel = {};
    PositionItemCellTemplate;
    _export;
    currentUserObj = JSON.parse(localStorage.getItem("user"));
    pageSize;
    timesheetExcelData: TimesheetExcelState[] = [];

    constructor(props) {
        super(props);
        this.state = {
            departmentList: [],
            divisionList: [],
            industryList: [],
            vendorList: [],
            departmentValue: "",
            divisionValue: "",
            industryValue: "",
            vendorValue: "",
            TimesheetInfo: [],
            allApproveCheck: false,
            searchString: "",
            isPageLoaded: false,
            openDialog: false,
            pageNumber: 1,
            showToastr: false,
            disableApproveBtn: true,
            loadNextItems: false,
            helpText: "Loading next timesheets..",
            endReachead: false,
            actionType: 1,
            timesheetExcelData: [],
            excelDataLoading: false,
            searchingData: false,
            comments: "",
            commentsError: false,
        };

        this.PositionItemCellTemplate = PositionItemTemplate({
            check: this.handleApproveBtnSelection.bind(this),
        });

        this.toggleDialog = this.toggleDialog.bind(this);
        if (isBrowser) {
            this.pageSize = 4;
        } else {
            this.pageSize = 3;
        }
    }

    export = async () => {
        await this.fetchTimesheetsForExcel();
    };

    toggleDialog() {
        this.setState({
            openDialog: !this.state.openDialog,
        });
    }

    filterChange = (event, listName) => {
        var obj = {};
        obj[listName] = this.filterData(event.filter, listName);
        this.setState(obj);
    };

    handleChange(e, modelProp) {
        var stateObj = {};
        stateObj[modelProp] = e.target.type == "checkbox" ? e.target.checked : e.target.value;
        this.setState(stateObj);
    }

    filterData(filter, listName) {
        const data = this.staticDropdownObj[listName].slice();
        return filterBy(data, filter);
    }

    scrollHandler = (event) => {
        const e = event.nativeEvent;
        if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
            var requestBody = {
                ClientId: this.currentUserObj.clientId,
                UserId: this.currentUserObj.u_Id,
                PageNumber: this.state.pageNumber + 1,
                PageSize: this.pageSize,
                SearchString: this.state.searchString,
            };

            this.setState({
                pageNumber: this.state.pageNumber + 1,
                loadNextItems: true,
            });

            var timesheetOptions = {
                method: "PUT",
                headers: { ...authHeader(), "Content-Type": "application/json" },
                body: JSON.stringify(requestBody),
            };

            fetch(`/api/Timesheet/GetTimesheetData`, timesheetOptions)
                .then(function (response) {
                    return response.json();
                })
                .then((data) => {
                    if (data.positions.length > 0) {
                        var positionData = this.state.TimesheetInfo.slice();
                        data.positions.forEach((element) => {
                            var index = positionData.findIndex((p) => p.position == element.position);
                            if (index != -1) {
                                element.positionReportees.forEach((rep) => {
                                    var repIndex = positionData[index].positionReportees.findIndex(
                                        (r) => r.reporteeName == rep.reporteeName && r.timeSheetApprover == rep.timeSheetApprover
                                    );
                                    if (repIndex != -1) {
                                        rep.reporteeTimesheetInfos.forEach((timesheet) => {
                                            positionData[index].positionReportees[repIndex].reporteeTimesheetInfos.push(timesheet);
                                        });
                                    } else {
                                        positionData[index].positionReportees.push(rep);
                                    }
                                });
                            } else {
                                positionData.push(element);
                            }
                        });
                        //positionData = positionData.concat(data.positions);
                        this.setState({
                            TimesheetInfo: positionData,
                            allApproveCheck: false,
                            loadNextItems: false,
                        });
                    } else {
                        this.setState({
                            loadNextItems: true,
                            helpText: "You've reached the end of the page.",
                            endReachead: true,
                        });
                    }
                });
        }
    };

    public render() {
        return (
            <div>
                <div className={"loaderContainer"} style={{ display: this.state.isPageLoaded ? "none" : "block" }}>
                    <ApproveTimesheetShimmer />
                </div>
                <div className="timesheetContainer" style={{ display: this.state.isPageLoaded ? "block" : "none" }}>
                    <div className="container d-none d-md-block" id="searchCard">
                        <div className="shadow">
                            <div className="row mt-2">
                                <div className="col-md-11 mx-auto pl-0 pr-0 ">
                                    <div className="input-group mt-4">
                                        <input
                                            type="text"
                                            value={this.state.searchString}
                                            onChange={(e) => this.handleChange(e, "searchString")}
                                            className="form-control border-right-0 text-dark"
                                            title="Providers| Vendors| Positions | Divisions"
                                            placeholder="Providers| Vendors| Positions | Divisions"
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter") {
                                                    this.fetchTimesheetData();
                                                }
                                            }}
                                        />
                                        <div className="input-group-append">
                                            <button className="btn-secondary search-icon border-left-0 search-button-focus" type="button">
                                                <FontAwesomeIcon
                                                    icon={faTimes}
                                                    className={"mr-3 text-primary"}
                                                    style={{ display: this.state.searchString != "" ? "inline-block" : "none" }}
                                                    onClick={() => this.clearSearch()}
                                                />
                                                <FontAwesomeIcon icon={faSearch} className={"mr-2"} onClick={this.fetchTimesheetData.bind(this)} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row ml-0 mr-0  mt-2">
                                <div className="col-12 d-flex">
                                    <div className="col-md-1 mb-2 mt-2 export-to-excel d-none d-md-block pl-0 pl-xl-3">
                                        <img
                                            src={require("../../assets/images/export_to_excel.png")}
                                            className=""
                                            alt="logo"
                                            style={{ width: "40px" }}
                                            onClick={this.export}
                                        />
                                    </div>
                                    <div className="col-md-auto ml-auto text-right mt-2 pr-0 pr-xl-3">
                                        <div className="row">
                                            <div className="col-auto text-right txt-clr-green-select font-weight-bold select-all text-right d-none d-md-block pr-0">
                                                {this.state.allApproveCheck ? "Select None" : "Select All"}
                                                <span className="grn-bg-icon-checkbox">
                                                    <label className="container-R d-flex col-12">
                                                        <input
                                                            type="checkbox"
                                                            onClick={this.checkAllPositionTimesheets.bind(this)}
                                                            defaultChecked={this.state.allApproveCheck}
                                                            checked={this.state.allApproveCheck}
                                                        />
                                                        <span className="checkmark-R"></span>
                                                    </label>
                                                </span>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    type="button"
                                                    className="btn button button-new  button-reject button-reject-new font-weight-bold ml-0 mr-2"
                                                    disabled={this.state.disableApproveBtn}
                                                    onClick={() => this.setState({ openDialog: true, actionType: 2 })}
                                                >
                                                    Reject
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn button button-new  button-approved  font-weight-bold"
                                                    disabled={this.state.disableApproveBtn}
                                                    onClick={() => this.setState({ openDialog: true, actionType: 1 })}
                                                >
                                                    Approve{" "}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container d-block d-md-none pl-0 pr-0" id="searchCardMob">
                        <div className="shadow">
                            <div className="row mt-1 ml-0 mr-0">
                                <div className="col-11 mx-auto pl-0 pr-0 ">
                                    <div className="input-group mt-4">
                                        <input
                                            type="text"
                                            value={this.state.searchString}
                                            onChange={(e) => this.handleChange(e, "searchString")}
                                            className="form-control border-right-0 placeholder-mobile searchText"
                                            title="Providers| Vendors| Positions | Divisions"
                                            placeholder="Providers| Vendors| Positions | Divisions"
                                        />
                                        <div className="input-group-append">
                                            <button className="btn-secondary search-icon border-left-0 search-button-focus" type="button">
                                                <FontAwesomeIcon
                                                    icon={faTimes}
                                                    className={"mr-3 text-primary"}
                                                    style={{ display: this.state.searchString != "" ? "inline-block" : "none" }}
                                                    onClick={this.clearSearch.bind(this)}
                                                />

                                                <FontAwesomeIcon icon={faSearch} className={"mr-2"} onClick={this.fetchTimesheetData.bind(this)} />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-11 mx-auto mt-2 pl-0 pr-0">
                                    <div className="row align-items-center ml-0 mr-0 d-flex justify-content-end">
                                        {/* <div className="col-2 col-md-2 mb-2 mt-2 export-to-excel">
                                        <img src={require('../../assets/images/export_to_excel.png')} className="" alt="logo" style={{ width: "40px" }} onClick={this.export} />

                                    </div> */}
                                        <div className="col-auto mb-1 mt-1 txt-clr-green font-weight-bold select-alll text-left pr-0">
                                            <span className="">{this.state.allApproveCheck ? "Select None" : "Select All"}</span>
                                            <span className="grn-bg-icon-checkbox mb-1">
                                                <label className="container-R d-flex col-12">
                                                    <input
                                                        type="checkbox"
                                                        onClick={this.checkAllPositionTimesheets.bind(this)}
                                                        checked={this.state.allApproveCheck}
                                                    />
                                                    <span className="checkmark-R"></span>
                                                </label>
                                            </span>
                                        </div>
                                        <div className="col-auto mb-1 pr-0 pl-0 ml-2">
                                            <button
                                                type="button"
                                                className="btn button button-reject font-weight-bold pl-3 pr-3"
                                                disabled={this.state.disableApproveBtn}
                                                onClick={() => this.setState({ openDialog: true, actionType: 2 })}
                                            >
                                                Reject
                                            </button>
                                        </div>
                                        <div className="col-auto mb-1 pr-0 pl-0 ml-1">
                                            <button
                                                type="button"
                                                disabled={this.state.disableApproveBtn}
                                                className="btn button button-approved font-weight-bold"
                                                onClick={() => this.setState({ openDialog: true, actionType: 1 })}
                                            >
                                                Approve
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mx-auto text-center mt-3">
                        <div style={{ display: this.state.searchingData ? "block" : "none" }}>
                            <ApproveTimesheetShimmer />
                        </div>
                    </div>

                    <div className="row ml-0 mr-0" style={{ display: this.state.searchingData ? "none" : "block" }}>
                        <div className="col-12 mx-auto mt-3 pl-0 pr-0" id="timesheetDataGrid">
                            {/* <ShimmerTab /> */}
                            <ExcelExport
                                ref={(exporter) => (this._export = exporter)}
                                data={this.state.timesheetExcelData}
                                filterable={true}
                                fileName={this.currentUserObj.u_Id ? this.currentUserObj.u_Id : "TimesheetData"}
                            >
                                <ExcelExportColumn field="vendorName" title="Vendor" width={100} />
                                <ExcelExportColumn field="endDate" title="Job End Date" width={100} />
                                <ExcelExportColumn field="position" title="Position" width={150} />
                                <ExcelExportColumn field="associate" title="Associate" width={150} />
                                <ExcelExportColumn field="division" title="Division" width={100} />
                                <ExcelExportColumn field="site" title="Site" width={150} />
                                <ExcelExportColumn field="timesheetDetail" title="Timesheet Details" width={150} />
                                <ExcelExportColumn field="period" title="Period" width={150} />
                                <ExcelExportColumn field="totalHours" title="Total Hours" width={150} />
                                <ExcelExportColumn field="billAmt" title="Total Billable" width={150} />
                                <ExcelExportColumn field="timesheetApprover" title="Timesheet Approver(s)" width={150} />
                            </ExcelExport>
                            <Grid
                                style={{ margin: "0" }}
                                onScroll={this.scrollHandler.bind(this)}
                                className="row px-0 mx-auto timesheetTblGrid"
                                data={this.state.TimesheetInfo}
                            >
                                <GridNoRecords>
                                    <div className="mt-2 font-medium d-block d-md-none">There is no data available :(</div>
                                    <div className="mt-2 font-extrabold d-none d-md-block">There is no data available :(</div>
                                </GridNoRecords>
                                <GridColumn field="position" title="Position" cell={this.PositionItemCellTemplate} headerClassName="positionHeader" />
                            </Grid>
                        </div>
                    </div>
                    <div
                        className="col-12 pb-0 pt-0 text-center pl-0 pr-0"
                        style={{ display: this.state.loadNextItems && this.state.TimesheetInfo.length > 0 ? "block" : "none" }}
                    >
                        <FontAwesomeIcon icon={faSyncAlt} className="mr-2" style={{ display: this.state.endReachead ? "none" : "inline-block" }} />
                        {this.state.helpText}
                    </div>
                </div>

                <div>
                    {this.state.openDialog && (
                        <Dialog title={"Timesheet Approval"} onClose={this.toggleDialog} style={{ width: "90%", marginLeft: "18px" }}>
                            <p style={{ margin: "18px", textAlign: "center" }}>
                                <span className="font-medium">
                                    Are you sure you want to {this.state.actionType == 1 ? "approve" : "reject"} the selected timesheets?
                                </span>
                            </p>
                            <label className={this.state.actionType == 2 ? "required" : ""}>Comments</label>
                            <textarea
                                placeholder="Enter Comment"
                                className="form-control"
                                value={this.state.comments}
                                onChange={(e) => this.setState({ comments: e.target.value })}
                            />
                            <label
                                style={{
                                    display: this.state.actionType == 2 && this.state.commentsError && this.state.comments == "" ? "block" : "none",
                                    color: "red",
                                    fontSize: "12px",
                                }}
                            >
                                Please provide comments.
                            </label>
                            <DialogActionsBar>
                                <button className="k-button" onClick={this.toggleDialog}>
                                    No
                                </button>
                                <button className="k-button yes" onClick={this.updateTimesheetData.bind(this)}>
                                    Yes
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>
                <NotificationGroup className="group-notification">
                    <Fade enter={true} exit={true}>
                        {this.state.showToastr && (
                            <Notification
                                type={{ style: "success", icon: true }}
                                closable={true}
                                onClose={() => {
                                    this.setState({ showToastr: false });
                                }}
                            >
                                <span>Selected Timesheet(s) {this.state.actionType == 1 ? "Approved" : "Rejected"} Successfully!</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>
            </div>
        );
    }

    public componentWillMount() {
        if (!localStorage.getItem("user")) {
            history.push("/Login");
        }
        document.title = "Ringo Anaylsis Reporting";
        this.fetchTimesheetData();
    }

    public async componentDidMount() {
        var pageTitleElem = document.getElementById("curPageTitle");
        pageTitleElem.innerText = "Timesheet Approval";
    }

    public clearSearch() {
        this.setState({
            searchString: "",
            pageNumber: 1,
            disableApproveBtn: true,
            allApproveCheck: false,
            searchingData: true,
            helpText: "Loading next timesheets..",
            endReachead: false,
        });
        this.fetchAllTimesheets();
    }

    private fetchTimesheetData() {
        var requestBody = {
            ClientId: this.currentUserObj.clientId,
            UserId: this.currentUserObj.u_Id,
            PageNumber: 1,
            PageSize: this.pageSize,
            SearchString: this.state.searchString,
        };

        this.setState({
            searchingData: true,
        });
        var timesheetOptions = {
            method: "PUT",
            headers: { ...authHeader(), "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
        };

        fetch(`/api/Timesheet/GetTimesheetData`, timesheetOptions).then(this.handleResponse.bind(this));
    }

    private fetchAllTimesheets() {
        var requestBody = {
            ClientId: this.currentUserObj.clientId,
            UserId: this.currentUserObj.u_Id,
            PageNumber: 1,
            PageSize: this.pageSize,
            SearchString: "",
            helpText: "Loading next timesheets..",
            endReachead: false,
        };

        var timesheetOptions = {
            method: "PUT",
            headers: { ...authHeader(), "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
        };

        fetch(`/api/Timesheet/GetTimesheetData`, timesheetOptions).then(this.handleResponse.bind(this));
    }

    private handleApproveBtnSelection(event, dataItem: PositionState, reporteeName, tagId, startDate, timeSheetApprover) {
        var positionState = this.state.TimesheetInfo.slice();
        var currentPositionIndex = positionState.findIndex((p) => p.position == dataItem.position);
        var currentReporteeIndex = positionState[currentPositionIndex].positionReportees.findIndex(
            (r) => r.reporteeName == reporteeName && r.timeSheetApprover == timeSheetApprover
        );
        var timesheetInfoIndex = positionState[currentPositionIndex].positionReportees[currentReporteeIndex].reporteeTimesheetInfos.findIndex(
            (t) => t.timesheetTagId == tagId && t.durationStart == startDate
        );

        positionState[currentPositionIndex].positionReportees[currentReporteeIndex].reporteeTimesheetInfos[
            timesheetInfoIndex
        ].isApproveSelected = !positionState[currentPositionIndex].positionReportees[currentReporteeIndex].reporteeTimesheetInfos[timesheetInfoIndex]
            .isApproveSelected;

        this.checkIfAllSelected();
        this.checkSelectionStatus();
        this.setState({
            TimesheetInfo: positionState,
        });
    }

    private checkAllPositionTimesheets() {
        var positionState = this.state.TimesheetInfo.slice();
        positionState.forEach((position) => {
            position.positionReportees.forEach((reportee) => {
                reportee.reporteeTimesheetInfos.forEach((timesheet) => {
                    timesheet.isApproveSelected = !this.state.allApproveCheck;
                });
            });
        });

        this.checkSelectionStatus();
        this.setState({
            TimesheetInfo: positionState,
            allApproveCheck: !this.state.allApproveCheck,
        });
    }

    private handleResponse(response) {
        return response.text().then(
            (text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if (response.status === 401) {
                        // auto logout if 401 response returned from api
                        logout();
                        //location.reload(true);
                    }

                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                this.setState({
                    TimesheetInfo: data.positions,
                    isPageLoaded: true,
                    pageNumber: 1,
                    disableApproveBtn: true,
                    allApproveCheck: false,
                    loadNextItems: false,
                    searchingData: false,
                    // endReachead:false
                });
            },
            (error) => {
                this.setState({
                    TimesheetInfo: [],
                    isPageLoaded: true,
                    pageNumber: 1,
                    disableApproveBtn: true,
                    allApproveCheck: false,
                    loadNextItems: false,
                    searchingData: false,
                    // endReachead:false
                });
            }
        );
    }

    private fetchTimesheetsForExcel() {
        this.setState({
            excelDataLoading: true,
        });
        var requestBody = {
            ClientId: this.currentUserObj.clientId,
            UserId: this.currentUserObj.u_Id,
            PageNumber: 1,
            PageSize: 200,
            SearchString: this.state.searchString,
        };

        var timesheetOptions = {
            method: "PUT",
            headers: { ...authHeader(), "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
        };

        fetch(`/api/Timesheet/GetTimesheetData`, timesheetOptions).then((response) =>
            response.text().then((text) => {
                const data = text && JSON.parse(text);
                this.createExcelExportData(data.positions);
            })
        );
    }

    private async createExcelExportData(positionState) {
        this.timesheetExcelData = [];
        positionState.forEach((pos) => {
            pos.positionReportees.forEach((reportee) => {
                reportee.reporteeTimesheetInfos.forEach((timesheet) => {
                    var timesheetPeriod = "";
                    timesheet.dayInfos.forEach((d, index) => {
                        if (index != 0) {
                            timesheetPeriod += " / " + d.dayName + ":" + d.dayWorkingHrs;
                        } else {
                            timesheetPeriod += d.dayName + ":" + d.dayWorkingHrs;
                        }
                    });
                    var excelTimesheetRow: TimesheetExcelState = {
                        associate: reportee.reporteeName,
                        position: pos.position,
                        site: pos.deptLocation,
                        billAmt: timesheet.billingAmt,
                        division: pos.division,
                        endDate: pos.endDate,
                        period: timesheet.durationStart + " - " + timesheet.durationEnd,
                        timesheetApprover: reportee.timeSheetApprover,
                        vendorName: pos.vendorName,
                        totalHours: timesheet.totalHrs,
                        timesheetDetail: timesheetPeriod,
                    };

                    this.timesheetExcelData.push(excelTimesheetRow);
                });
            });
        });

        this.setState({
            timesheetExcelData: this.timesheetExcelData,
            excelDataLoading: false,
        });

        this._export.save();
    }

    private checkIfAllSelected() {
        var positionState = this.state.TimesheetInfo.slice();
        var isAllchecked: boolean = true;
        positionState.forEach((position) => {
            position.positionReportees.forEach((reportee) => {
                reportee.reporteeTimesheetInfos.forEach((timesheet) => {
                    if (timesheet.isApproveSelected == false || !timesheet.isApproveSelected) {
                        isAllchecked = false;
                    }
                });
            });
        });

        this.setState({
            allApproveCheck: isAllchecked,
        });
    }

    public checkSelectionStatus() {
        var positionState = this.state.TimesheetInfo.slice();
        var isAnySelected: boolean = false;
        positionState.forEach((position) => {
            position.positionReportees.forEach((reportee) => {
                reportee.reporteeTimesheetInfos.forEach((timesheet) => {
                    if (timesheet.isApproveSelected) {
                        isAnySelected = true;
                    }
                });
            });
        });

        this.setState({
            disableApproveBtn: !isAnySelected,
        });
    }

    private updateTimesheetData() {
        if (this.state.actionType == 2 && this.state.comments == "") {
            this.setState({ commentsError: true });
            return;
        }

        var positionState = this.state.TimesheetInfo.slice();
        var jobIdInfoArray: string[] = [];
        var vendorId = "";
        positionState.forEach((position) => {
            position.positionReportees.forEach((reportee) => {
                reportee.reporteeTimesheetInfos.forEach((timesheet) => {
                    if (timesheet.isApproveSelected) {
                        var jobString =
                            timesheet.timesheetTagId + "," + timesheet.durationStart + "," + timesheet.durationEnd + "," + this.state.comments;
                        vendorId = position.vendorID;
                        jobIdInfoArray.push(jobString);
                    }
                });
            });
        });

        var timesheetPayload = {
            JobIds: jobIdInfoArray.join("|"),
            VendorId: vendorId,
            UpdatedBy: this.currentUserObj.u_Id,
            ApprovalStatus: this.state.actionType == 1 ? 1 : 3,
        };

        var requestOptions = {
            method: "PUT",
            headers: { ...authHeader(), "Content-Type": "application/json" },
            body: JSON.stringify(timesheetPayload),
        };

        fetch(`/api/Timesheet/UpdateTimesheet`, requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                console.log(data);
                this.setState({
                    openDialog: false,
                    showToastr: true,
                    comments: "",
                    commentsError: false,
                });

                setTimeout(() => {
                    this.setState({
                        showToastr: false,
                        disableApproveBtn: true,
                        allApproveCheck: false,
                        helpText: "Loading next timesheets..",
                        endReachead: false,
                    });
                    this.fetchTimesheetData();
                }, 3000);
            });
    }
}

export default connect(
    (state: ApplicationState) => state.global, // Selects which state properties are merged into the component's props
    GlobalStore.actionCreators // Selects which action creators are merged into the component's props
)(ApproveTimesheet as any);
