import React, { Component, createRef } from "react";
import { ITimesheetDetailsState, ITimesheetRow } from "./ITimesheetDetailsState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faClock, faDollarSign, faPaperclip, faFilePdf, faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { authHeader, formatter, history, numberFormatter } from "../../HelperMethods";
import "../TimesheetDetails/TimesheetDetails.css";
import * as GlobalStore from "../../store/Global";
import { RouteComponentProps, Link } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import ShimmerTab from "../Shared/ShimmerTab";

export type GlobalProps = GlobalStore.GlobalState & typeof GlobalStore.actionCreators & RouteComponentProps<{ location: any }>;

export class TimesheetDetails extends React.Component<GlobalProps, ITimesheetDetailsState> {
    private previousState = this.props.location.state;
    myRef;
    currentUserObj = JSON.parse(localStorage.getItem("user"));

    constructor(props) {
        super(props);
        this.state = {
            clientName: "",
            divisionName: "",
            timesheetList: [],
            openDialog: false,
            pageNumber: 1,
            showToastr: false,
            actionType: 1,
            clientComments: "",
            commentsError: "",
            showErrorToastr: false,
            dataLoaded: false,
            totalBillableHrs: "0.00",
            totalOvertimeHrs: "0.00",
            submittedDate: "06/10/2019 10:30:45 AM EST",
            totalGuaranteedHrs: "0.00",
            clientDivisionId: "",
            clientLocationId: "",
            overtimeThreshold: 40,
        };

        this.toggleDialog = this.toggleDialog.bind(this);
        this.myRef = createRef();
    }

    toggleDialog() {
        this.setState({
            openDialog: !this.state.openDialog,
        });
    }

    toggleScroll() {
        this.myRef.current.scrollIntoView({ behavior: "smooth" });
    }

    render() {
        return (
            <div className="col-12 pl-0 pr-0 mt-2">
                <div className="row ml-0 mr-0 d-block d-md-none d-flex">
                    <div className="col-12 col-md-10 mx-auto text-left pl-0 pr-0">
                        <div className="row justify-content-start ">
                            <div className="col-6 mx-auto pr-0">
                                <span className="mobileBlock">Provider:</span>
                                <span className="font-weight-bold Charcoal-blck">{this.previousState.reportee}</span>
                            </div>
                            <div className="col-6 text-right pl-0">
                                <span className="mobileBlock">Position: </span>
                                <span className="font-weight-bold Charcoal-blck">{this.previousState.position}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row ml-0 mr-0 d-block d-md-none d-flex mt-1">
                    <div className="col-12 col-md-10 mx-auto text-left pl-0 pr-0">
                        <div className="row justify-content-start ">
                            <div className="col-6 mx-auto pr-0">
                                <span className="mobileBlock">Pay Period:</span>
                                <span className="font-weight-bold Charcoal-blck">
                                    {this.previousState.durationStart + " - " + this.previousState.durationEnd}
                                </span>
                            </div>
                            <div className="col-6 text-right pl-0">
                                <span className="mobileBlock">Division:</span>
                                <span className="font-weight-bold Charcoal-blck">{this.previousState.division}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row ml-0 mr-0 d-block d-md-none d-flex mt-1">
                    <div className="col-12 col-md-10 mx-auto text-left pl-0 pr-0">
                        <div className="row justify-content-start ">
                            <div className="col-6 mx-auto pr-0">
                                <span className="mobileBlock">Dept/Location:</span>
                                <span className="font-weight-bold Charcoal-blck">{this.previousState.deptLocation}</span>
                            </div>
                            <div className="col-6 text-right pl-0">
                                <span className="mobileBlock">Vendor:</span>
                                <span className="font-weight-bold Charcoal-blck">{this.previousState.vendor}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row ml-0 mr-0 d-block d-md-none d-flex mt-1">
                    <div className="col-12 col-md-10 mx-auto text-left pl-0 pr-0">
                        <div className="row justify-content-start ">
                            <div className="col-12 mx-auto pr-0">
                                <span className="mobileBlock">
                                    Submitted: <span className="font-weight-bold">{this.state.submittedDate}</span>
                                </span>
                                {/* <span className="font-weight-bold Charcoal-blck">06/10/2019 10:30:45 AM EST</span> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-none d-md-block mt-1 font-medium">
                    <div className="row ml-0 mr-0 d-none d-md-block d-flex mt-1">
                        <div className="col-md-12 mx-auto text-left pl-0 pr-0">
                            <div className="row justify-content-start ">
                                <div className="col-6 mx-auto pr-0">
                                    <span className="mobileBlock">Provider: </span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.reportee}</span>
                                </div>
                                <div className="col-6 text-right pl-0">
                                    <span className="mobileBlock">Position: </span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.position}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row ml-0 mr-0 d-none d-md-block d-flex mt-1">
                        <div className="col-md-12 mx-auto text-left pl-0 pr-0">
                            <div className="row justify-content-start ">
                                <div className="col-6 mx-auto pr-0">
                                    <span className="mobileBlock">Pay Period: </span>
                                    <span className="font-weight-bold Charcoal-blck">
                                        {this.previousState.durationStart + " - " + this.previousState.durationEnd}
                                    </span>
                                </div>
                                <div className="col-6 text-right pl-0">
                                    <span className="mobileBlock">Division: </span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.division}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row ml-0 mr-0 d-none d-md-block d-flex mt-1">
                        <div className="col-md-12 mx-auto text-left pl-0 pr-0">
                            <div className="row justify-content-start ">
                                <div className="col-6 mx-auto pr-0">
                                    <span className="mobileBlock">Dept/Location: </span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.deptLocation}</span>
                                </div>
                                <div className="col-6 text-right pl-0">
                                    <span className="mobileBlock">Vendor: </span>
                                    <span className="font-weight-bold Charcoal-blck">{this.previousState.vendor}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row ml-0 mr-0 d-none d-md-block d-flex mt-1">
                        <div className="col-12 col-md-12 mx-auto text-left pl-0 pr-0">
                            <div className="row">
                                <div className="col-12 mx-auto pr-0">
                                    <span className="mobileBlock">
                                        Submitted: <span className="font-weight-bold">{this.state.submittedDate}</span>
                                    </span>
                                    {/* <span className="font-weight-bold Charcoal-blck">06/10/2019 10:30:45 AM EST</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row ml-0 mr-0">
                    <div className="col-12 col-md-12 mx-auto pl-0 pr-0">
                        <hr className="pb-0 mt-0" />
                    </div>
                </div>

                <div className="row mt-1 d-none d-md-block">
                    <div className="col-12 col-md-12 mx-auto">
                        <div className="row ml-0 mr-0 justify-content-end">
                            <div className="col-auto mt-2">
                                <Link to={{ pathname: "/ProviderContact", state: this.previousState }}>
                                    <FontAwesomeIcon icon={faFileSignature} className={"active-icon"} />
                                    <span className="ml-1 active-icon">Contract</span>
                                </Link>
                            </div>
                            <div className="col-auto mt-2">
                                <Link to={{ pathname: "/ClientRateCard", state: this.previousState }}>
                                    <FontAwesomeIcon icon={faDollarSign} className={"active-icon"} />
                                    <span className="ml-1 active-icon">Rate Card</span>
                                </Link>
                            </div>
                            <div className="col-auto">
                                <button
                                    type="button"
                                    className="btn button button-reject font-weight-bold ml-2 mr-4"
                                    onClick={this.onReject.bind(this)}
                                >
                                    Reject
                                </button>
                                <button type="button" className="btn button button-regular font-weight-bold" onClick={this.onApprove.bind(this)}>
                                    Approve
                                </button>
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn button button-regular font-weight-bold" onClick={window.print}>
                                    <FontAwesomeIcon icon={faPrint} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ml-0 mr-0 d-block d-md-none d-flex mt-1">
                    <div className="col-12 col-md-10 mx-auto text-left pl-0 pr-0">
                        <div className="row justify-content-center font-medium ">
                            <div className="col-5 pl-0 pr-0 text-right mr-2">
                                <Link to={{ pathname: "/ProviderContact", state: this.previousState }} className="Charcoal-black">
                                    <FontAwesomeIcon icon={faFileSignature} className={"active-icon"} />
                                    <span className="ml-1 font-weight-regular active-icon">Contract</span>
                                </Link>
                            </div>
                            <div className="col-5 pl-0 pr-0 ml-5">
                                <Link to={{ pathname: "/ClientRateCard", state: this.previousState }} className="Charcoal-black">
                                    <FontAwesomeIcon icon={faDollarSign} className={"active-icon"} />
                                    <span className="ml-1 font-weight-regular active-icon">Rate Card</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ml-0 mr-0 mt-2">
                    <div className="col-12 col-md-12 mx-auto pl-0 pr-0">
                        <hr className="pb-0 mt-0 hidePrint" />
                    </div>
                </div>

                <div className="row ml-0 mr-0 mt-1 d-block d-md-none d-flex">
                    <div className="col-12 col-md-10 mx-auto pl-0 pr-0">
                        <div className="row ml-0 mr-0 justify-content-end">
                            <div className="col-6 text-right">
                                <button
                                    type="button"
                                    className="btn button button-reject font-weight-bold pr-4 pl-4"
                                    onClick={this.onReject.bind(this)}
                                >
                                    Reject
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    type="button"
                                    className="btn button button-regular font-weight-bold pr-4 pl-4"
                                    onClick={this.onApprove.bind(this)}
                                >
                                    Approve
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row ml-0 mr-0 mt-2 d-block d-md-none">
                    <div className="col-12 col-md-12 mx-auto pl-0 pr-0">
                        <div className="shadow">
                            <div className="table-responsive">
                                <table className="custom-tbl table table-striped value-card-bg BgListHeading">
                                    <thead>
                                        <tr>
                                            <th className="border-bottom-0 border-right">Total Hours</th>
                                            <th className="border-0 d-none d-md-block"></th>
                                            <th className="border-bottom-0 border-right">Overtime Hours</th>
                                            <th className="border-0 d-none d-md-block"></th>
                                            <th className="border-bottom-0">Guaranteed Hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-top-0 value-card-bg txt-clr font-weight-bold border-right font-extra-bold pt-1 pb-1 text-right">
                                                {this.state.totalBillableHrs}
                                            </td>
                                            <td className="border-0 value-card-bg d-none d-md-block"></td>
                                            <td className="border-top-0 value-card-bg txt-clr font-weight-bold border-right font-extra-bold pt-1 pb-1 text-right">
                                                {this.state.totalOvertimeHrs}
                                            </td>
                                            <td className="border-0 value-card-bg d-none d-md-block"></td>
                                            <td className="border-top-0 value-card-bg txt-clr font-weight-bold font-extra-bold pt-1 pb-1 text-right">
                                                {this.state.totalGuaranteedHrs}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ml-0 mr-0 mt-2 d-none d-md-block">
                    <div className="col-12 col-md-12 mx-auto pl-0 pr-0">
                        <div className="shadow">
                            <div className="table-responsive" id="print">
                                <table className="custom-tbl table table-striped">
                                    <thead>
                                        <tr className="value-card-bg">
                                            <th className="border-bottom-0 border-right value-card-bg">Total Hours</th>
                                            <th className="border-0 d-none d-md-block value-card-bg"></th>
                                            <th className="border-bottom-0 border-right value-card-bg">Overtime Hours</th>
                                            <th className="border-0 d-none d-md-block value-card-bg"></th>
                                            <th className="border-bottom-0 value-card-bg">Guaranteed Hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-top-0 value-card-bg txt-clr font-weight-bold border-right font-extra-bold pt-1 pb-1 text-right">
                                                {this.state.totalBillableHrs}
                                            </td>
                                            <td className=" border-top-0  value-card-bg txt-clr font-weight-bold font-extra-bold pt-1 pb-1 text-right"></td>
                                            <td className="border-top-0 value-card-bg txt-clr font-weight-bold border-right font-extra-bold pt-1 pb-1 text-right">
                                                {this.state.totalOvertimeHrs}
                                            </td>
                                            <td className=" border-top-0  value-card-bg txt-clr font-weight-bold font-extra-bold pt-1 pb-1 text-right"></td>
                                            <td className="border-top-0 value-card-bg txt-clr font-weight-bold font-extra-bold pt-1 pb-1 text-right">
                                                {this.state.totalGuaranteedHrs}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mx-auto text-center mt-2">
                    <div style={{ display: this.state.dataLoaded ? "none" : "block" }}>
                        <ShimmerTab />
                    </div>
                </div>
                <div id="dailyTimesheetContainer" style={{ display: this.state.dataLoaded ? "block" : "none" }}>
                    {this.state.timesheetList && this.state.timesheetList.length > 0 ? (
                        this.state.timesheetList.map((row, index) => (
                            <div key={index}>
                                <div className="row ml-0 mr-0 mt-3">
                                    <div className="col-12 col-md-12 mx-auto pl-0 pr-0">
                                        <span className="font-weight-bold">{row.timesheetDate}</span>
                                    </div>
                                </div>

                                <div className="row ml-0 mr-0">
                                    <div className="col-12 col-md-12 mx-auto pl-0 pr-0">
                                        <hr />
                                    </div>
                                </div>

                                <div className="row ml-0 mr-0 mt-0">
                                    <div className="col-12 col-md-12 mx-auto pl-0 pr-0">
                                        <div className="shadow">
                                            <div className="table-responsive">
                                                <table className="custom-tbl table table-striped">
                                                    <thead>
                                                        <tr className="th">
                                                            <th>Service Type</th>
                                                            <th className="pr-0">Time In</th>
                                                            <th className="pr-0">Time Out</th>
                                                            <th className="text-right">Hours</th>
                                                            <th className="text-right">Amt</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {row.serviceTaskDetailList.map((serviceTask, index) => (
                                                            <tr key={"serviceTask" + index} style={{ borderBottom: "1px solid #dee2e6" }}>
                                                                <td
                                                                    className="border-top-0 txt-clr-blue font-weight-bold serviceType"
                                                                    title={serviceTask.serviceType}
                                                                >
                                                                    {serviceTask.serviceType}
                                                                </td>
                                                                <td className="border-top-0 pr-0">{serviceTask.startTime}</td>
                                                                <td className="border-top-0 pr-0">{serviceTask.endTime}</td>
                                                                <td className="border-top-0 text-right">
                                                                    {serviceTask.totalHrs != "0" ? serviceTask.totalHrs : ""}
                                                                </td>
                                                                <td className="text-right">{formatter.format(serviceTask.billAmount)}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 mx-auto font-weight-bold pl-0 pr-0">
                                    <div className="row bg-light-blue ml-0 mr-0">
                                        <div className="col-6 mb-1 mt-1">
                                            <span className="grn-bg-icon just mr-2">
                                                <FontAwesomeIcon icon={faClock} />
                                            </span>
                                            <span className="Charcoal-black font-weight-bold mt-1">{this.calculateDurationSum(row)}</span>
                                        </div>
                                        <div className="col-6 text-right mt-1">
                                            <span className="grn-bg-icon just mr-2">
                                                <FontAwesomeIcon icon={faDollarSign} />
                                            </span>
                                            <span className="Charcoal-black font-weight-bold">{this.calculateAmountSum(row)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <table className="custom-tbl table table-striped">
                            <thead>
                                <tr className="th">
                                    <th>Service Type</th>
                                    <th className="pr-0">Time In</th>
                                    <th className="pr-0">Time Out</th>
                                    <th className="text-right">Hours</th>
                                    <th className="text-right">Amt</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={5} className="text-center">
                                        No Data to Display
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>

                <div className="row ml-0 mr-0 mt-2">
                    <div className="col-12 col-md-12 mx-auto pl-0 pr-0">
                        <div className="form-group" ref={this.myRef}>
                            <textarea
                                className="form-control font-regular grainsboro-clr"
                                value={this.state.clientComments}
                                onChange={(e) => this.setState({ clientComments: e.target.value, commentsError: "" })}
                                rows={3}
                                id="clientComment"
                                name="text"
                                placeholder="Enter Comment"
                            ></textarea>
                            <label style={{ display: this.state.commentsError.length > 0 ? "block" : "none", color: "red", fontSize: "12px" }}>
                                {this.state.commentsError}
                            </label>
                        </div>
                    </div>
                </div>

                {this.state.timesheetList.filter((t) => t.docName != "" && t.docName).length > 0 ? (
                    <div className="row mt-1 d-md-block">
                        <div className="col-12 mx-auto ">
                            <div className="col-12 shadow">
                                <div className="row ml-0 mr-0 mb-1">
                                    <div className="col-12 col-md-12 mx-auto d-flex mt-2">
                                        <div className="bg-export-icon">
                                            <FontAwesomeIcon icon={faPaperclip} />
                                        </div>
                                        <div className="text-dark ml-2">Attached Documents</div>
                                    </div>
                                </div>
                                <hr className="mt-0 mb-0" />
                                {this.state.timesheetList.map((t) =>
                                    t.docName != "" && t.docName ? (
                                        <div className="row ml-0 mr-0 mb-2">
                                            <div className="col-12 col-md-12 mx-auto d-flex mt-1">
                                                <div className="bg-export-icon">
                                                    <FontAwesomeIcon icon={faFilePdf} />
                                                </div>
                                                <div className="ml-2 txt-clr-blue font-weight-bold">
                                                    <a
                                                        href={
                                                            window.location.href.replace(window.location.pathname, "") +
                                                            "/uploads/" +
                                                            t.timesheetId +
                                                            "/" +
                                                            t.docName
                                                        }
                                                    >
                                                        {t.docName}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}

                <div className="row ml-0 mr-0 mt-1 d-block d-md-none d-flex mb-4">
                    <div className="col-12 col-md-10 mx-auto pl-0 pr-0">
                        <div className="row ml-0 mr-0 justify-content-end">
                            <div className="col-6 text-right">
                                <button
                                    type="button"
                                    className="btn button button-reject font-weight-bold pr-4 pl-4"
                                    onClick={this.onReject.bind(this)}
                                >
                                    Reject
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    type="button"
                                    className="btn button button-regular font-weight-bold pr-4 pl-4"
                                    onClick={this.onApprove.bind(this)}
                                >
                                    Approve
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    {this.state.openDialog && (
                        <Dialog title={"Timesheet Approval"} onClose={this.toggleDialog} style={{ width: "90%", marginLeft: "20px" }}>
                            <p style={{ margin: "20px", textAlign: "center" }}>
                                Are you sure you want to {this.state.actionType == 1 ? "approve" : "reject"} the timesheet?
                            </p>
                            <DialogActionsBar>
                                <button className="k-button" onClick={this.toggleDialog}>
                                    No
                                </button>
                                <button className="k-button" onClick={this.updateTimesheetDetails.bind(this)}>
                                    Yes
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>
                <NotificationGroup className="group-notification">
                    <Fade enter={true} exit={true}>
                        {this.state.showToastr && (
                            <Notification
                                type={{ style: "success", icon: true }}
                                closable={true}
                                onClose={() => {
                                    this.setState({ showToastr: false });
                                    window.location.reload();
                                }}
                            >
                                <span>Timesheet {this.state.actionType == 1 ? "Approved" : "Rejected"} Successfully!</span>
                            </Notification>
                        )}

                        {this.state.showErrorToastr && (
                            <Notification
                                type={{ style: "error", icon: true }}
                                closable={true}
                                onClose={() => {
                                    this.setState({ showErrorToastr: false });
                                }}
                            >
                                <span>Please provide rejection comments!</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>
            </div>
        );
    }

    public async componentWillMount() {
        // this.props.updatePageName("Timesheet Details");
        this.getTimesheetDetails();
    }

    public async componentDidMount() {
        var pageTitleElem = document.getElementById("curPageTitle");
        pageTitleElem.innerText = "Timesheet Details";
    }

    public calculateAmountSum(row: ITimesheetRow) {
        var billSum = 0;
        row.serviceTaskDetailList.forEach((element) => {
            billSum += element.billAmount ? element.billAmount : 0;
        });

        return numberFormatter.format(billSum);
    }

    public calculateDurationSum(row: ITimesheetRow) {
        var durationSum = 0;
        row.serviceTaskDetailList.forEach((element) => {
            durationSum += element.totalMins ? element.totalMins : 0;
        });

        return Math.trunc(durationSum / 60).toString() + "." + (durationSum % 60);
    }

    public onReject() {
        if (this.state.clientComments != "") {
            this.setState({
                openDialog: true,
                actionType: 2,
            });
        } else {
            this.setState({
                commentsError: "Please provide rejection comments.",
                showErrorToastr: true,
            });
            this.toggleScroll();
            setTimeout(() => {
                this.setState({
                    showErrorToastr: false,
                });
            }, 3000);
        }
    }

    public onApprove() {
        this.setState({
            openDialog: true,
            actionType: 1,
            commentsError: "",
        });
    }

    private getTimesheetDetails() {
        var requestBody = {
            JobId: this.previousState.jobId, //'JOBS-0513673EC4464EC5BD31667832E57D09',
            StartDate: this.previousState.durationStart, //new Date('03/06/2019'),
            EndDate: this.previousState.durationEnd, //new Date(this.previousState.durationEnd),new Date('09/06/2019'),
            RequestId: this.previousState.requestId, //this.previousState.requestId'REQU-9D0B39D4CE1B4A26BEC15687E1D3F5D2'
        };

        var requestOptions = {
            method: "PUT",
            headers: { ...authHeader(), "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
        };

        fetch(`/api/Timesheet/GetTimesheetDetails`, requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then(
                (detailData) => {
                    this.setState({
                        clientName: detailData.clientName,
                        divisionName: detailData.divisionName,
                        deptLocation: detailData.deptLocation,
                        submittedDate: detailData.submittedDate ? detailData.submittedDate : this.state.submittedDate,
                        timesheetList: detailData.timesheetList ? detailData.timesheetList : [],
                        clientLocationId: detailData.clientLocationId ? detailData.clientLocationId : "",
                        clientDivisionId: detailData.clientDivisionId ? detailData.clientDivisionId : "",
                        overtimeThreshold: detailData.overtimeThreshold,
                        // totalGuaranteedHrs:  detailData.guaranteedHrs ? detailData.guaranteedHrs + '.0' : '',
                        dataLoaded: true,
                    });
                    this.calculateTotalHours();

                    this.previousState.clientLocationId = detailData.clientLocationId ? detailData.clientLocationId : "";
                    this.previousState.clientDivisionId = detailData.clientDivisionId ? detailData.clientDivisionId : "";
                },
                (error) => {
                    this.setState({
                        dataLoaded: true,
                    });
                }
            );
    }

    private calculateTotalHours() {
        var otThresholdMins = this.state.overtimeThreshold * 60;
        var timesheetData = this.state.timesheetList.slice();
        var totalCalMins = 0;
        var totalGuaranteedMins = 0;
        var totalOvertimeMins = 0;
        timesheetData.forEach((data) => {
            data.serviceTaskDetailList.forEach((s) => {
                totalCalMins += s.totalMins;
                if (s.serviceType.indexOf("Guarantee") > -1) {
                    totalGuaranteedMins += s.totalMins;
                }
                if (s.serviceType.indexOf("Overtime") > -1) {
                    totalOvertimeMins += s.totalMins;
                }
            });
        });

        var remainingMins = totalCalMins - totalOvertimeMins;
        totalOvertimeMins = remainingMins > otThresholdMins ? totalCalMins - otThresholdMins : totalOvertimeMins;
        var totalGuaranteedHrs = Math.trunc(totalGuaranteedMins / 60).toString() + "." + (totalGuaranteedMins % 60);
        var totalHrs = Math.trunc(totalCalMins / 60).toString() + "." + (totalCalMins % 60);
        var totalOvertimeHrs = Math.trunc(totalOvertimeMins / 60).toString() + "." + (totalOvertimeMins % 60);
        this.setState({
            totalBillableHrs: totalHrs,
            totalGuaranteedHrs: totalGuaranteedHrs,
            totalOvertimeHrs: totalOvertimeHrs,
        });
    }

    private updateTimesheetDetails() {
        var jobString =
            this.previousState.jobId +
            "," +
            this.previousState.durationStart +
            "," +
            this.previousState.durationEnd +
            "," +
            this.state.clientComments;
        var timesheetPayload = {
            JobIds: jobString,
            VendorId: this.previousState.vendorId,
            UpdatedBy: this.currentUserObj.u_Id,
            ApprovalStatus: this.state.actionType == 1 ? 1 : 3,
        };

        var requestOptions = {
            method: "PUT",
            headers: { ...authHeader(), "Content-Type": "application/json" },
            body: JSON.stringify(timesheetPayload),
        };

        fetch(`/api/Timesheet/UpdateTimesheet`, requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                console.log(data);
                this.setState({
                    openDialog: false,
                    showToastr: true,
                });

                setTimeout(() => {
                    this.setState({ showToastr: false });
                    history.push("/ApproveTimesheet");
                }, 3000);
            });
    }
}

export default connect(
    (state: ApplicationState) => state.global, // Selects which state properties are merged into the component's props
    GlobalStore.actionCreators // Selects which action creators are merged into the component's props
)(TimesheetDetails as any);
