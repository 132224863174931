/* eslint-disable */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faDollarSign, faEye, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { GridCell } from '@progress/kendo-react-grid';
import { PositionReportee, ReporteeTimesheetInfo, PositionState } from './IApproveTimesheetState';
import { history, numberFormatter } from '../../HelperMethods';

export function PositionItemTemplate({ check }) {
    return class extends GridCell {
        render() {
            const { dataItem } = this.props;
            return (
                <td className="positionItemRow pl-0 pr-0 ">
                    {
                        dataItem.positionReportees.map((reportee: PositionReportee, index) => {
                            return <div className="pl-0 pr-0" key={"posReportee" + index}>

                                <div className="container px-0">
                                    <div className="row ml-0 mr-0">
                                        <div className="col-6 col-md-6 mx-auto pr-0">
                                            <span className="mobileBlock">Division: </span>
                                            <span className="font-weight-bold text-overflow">
                                                {dataItem.division}
                                            </span>
                                        </div>
                                        <div className="col-6 col-md-6 text-right pl-0">
                                            <span className="mobileBlock">Dept/Location: </span>
                                            <span className="font-weight-bold text-overflow">{dataItem.deptLocation}</span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row ml-0 mr-0">
                                        <div className="col-6 col-md-6 mx-auto pr-0">
                                            <span className="mobileBlock">Vendor: </span>
                                            <span className="font-weight-bold text-overflow">{dataItem.vendorName}</span>
                                        </div>
                                        <div className="col-6 col-md-6 text-right pl-0">
                                            <span className="mobileBlock">Position: </span>
                                            <span className="font-weight-bold text-overflow">{dataItem.position}</span>
                                        </div>

                                    </div>
                                    <hr />
                                    <div className="row ml-0 mr-0">
                                        <div className="col-6 col-md-6 mx-auto pr-0">
                                            <span className="mobileBlock">Reports to:</span>
                                            <span className="font-weight-bold text-overflow"> {dataItem.reportTo}</span>
                                        </div>
                                        <div className="col-6 col-md-6 text-right pl-0">
                                            <span className="mobileBlock">End Date:</span>
                                            <span className="font-weight-bold text-overflow"> {reportee.endDate}</span>
                                        </div>

                                    </div>
                                </div>


                                <div className="container shadow card-ts-bg-clr pb-1 mb-3" key={reportee.timeSheetApprover + index}>
                                    <div className="row mt-3">
                                        <div className="col-12 col-md-auto mb-2 mt-2 d-none d-md-block">
                                            <span className="font-weight-bold providerCls">{reportee.reporteeName}</span>
                                            <span className="ml-2 font-weight-bold">{reportee.reporteeTimesheetInfos[0].durationStart + " - " + reportee.reporteeTimesheetInfos[0].durationEnd}</span>
                                        </div>

                                        <div className="col-12 mb-2 mt-2 d-block d-md-none">
                                            <span className="font-weight-bold providerCls">{reportee.reporteeName}</span>
                                            <br />
                                            <span className="font-weight-bold">{reportee.reporteeTimesheetInfos[0].durationStart + " - " + reportee.reporteeTimesheetInfos[0].durationEnd}
                                                <a href="#" className="icon-clr-blue ml-3 mt-2" onClick={() => this.redirectToDetailsPage(reportee.reporteeTimesheetInfos[0], reportee.reporteeName, dataItem)}>
                                                    <FontAwesomeIcon icon={faFileAlt} className={"lead"} />
                                                </a>
                                            </span>
                                            <span className="grn-bg-icon-checkbox ml-5">
                                                <label className="container-R d-flex">
                                                    <input type="checkbox" checked={reportee.reporteeTimesheetInfos[0].isApproveSelected}
                                                        onChange={(event) => check(event, dataItem, reportee.reporteeName, reportee.reporteeTimesheetInfos[0].timesheetTagId, reportee.reporteeTimesheetInfos[0].durationStart, reportee.timeSheetApprover)} />
                                                    <span className="checkmark-R gridCheckbox"></span>
                                                </label>
                                            </span>
                                        </div>

                                        <div className="col-md-auto ml-md-auto d-flex mt-2 text-right">
                                            <div className="mr-2 d-none d-md-block">
                                                <span className="grn-bg-icon just mr-2">
                                                    <FontAwesomeIcon icon={faClock} />
                                                </span>
                                                <span>{reportee.reporteeTimesheetInfos[0].totalHrs}</span>

                                            </div>
                                            <div className="d-none d-md-block">
                                                <span className="grn-bg-icon just mr-2">
                                                    {/* <FontAwesomeIcon icon={faDollarSign} /> */}
                                                    <FontAwesomeIcon icon={faDollarSign} />
                                                </span>
                                                <span>{numberFormatter.format(reportee.reporteeTimesheetInfos[0].billingAmt)}</span>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-auto">
                                            <table className="table table-condensed table-responsive">
                                                <thead>
                                                    <tr className="txt-clr-blue font-weight-bold">
                                                        {reportee.reporteeTimesheetInfos[0].dayInfos.map((dayInfo, index) => (
                                                            <th key={index} >{dayInfo.dayName}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="font-weight-bold">
                                                        {reportee.reporteeTimesheetInfos[0].dayInfos.map((dayInfo, index) => (
                                                            <td key={index}>{dayInfo.dayWorkingHrs}</td>
                                                        ))}
                                                    </tr>

                                                </tbody>
                                            </table>

                                            <div className="col-12 mt-1 font-weight-bold pl-0 pr-0">
                                                <div className="row">
                                                    <div className="col-6 d-bock d-md-none">
                                                        <span className="grn-bg-icon just mr-2">
                                                            <FontAwesomeIcon icon={faClock} />
                                                        </span>
                                                        <span>{reportee.reporteeTimesheetInfos[0].totalHrs}</span>
                                                    </div>
                                                    <div className="col-6 d-bock d-md-none text-right">
                                                        <span className="grn-bg-icon just mr-2">
                                                            {/* <i className="fas fa-sack-dollar"></i> */}
                                                            <FontAwesomeIcon icon={faDollarSign} />
                                                        </span>
                                                        <span>{numberFormatter.format(reportee.reporteeTimesheetInfos[0].billingAmt)}</span>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-auto ml-md-auto d-flex mt-1 text-right">
                                            <div className="row ml-0 mr-0">
                                                <div className="d-none d-md-block">
                                                    <a href="#" className="icon-clr-blue mt-2" onClick={() => this.redirectToDetailsPage(reportee.reporteeTimesheetInfos[0], reportee.reporteeName, dataItem)}>
                                                        <FontAwesomeIcon icon={faFileAlt} className={"lead"} />
                                                    </a>
                                                </div>
                                                <div className=" d-none d-md-block">
                                                    <label className="container-R d-flex col-12">
                                                        <input type="checkbox" checked={reportee.reporteeTimesheetInfos[0].isApproveSelected}
                                                            onChange={(event) => check(event, dataItem, reportee.reporteeName, reportee.reporteeTimesheetInfos[0].timesheetTagId, reportee.reporteeTimesheetInfos[0].durationStart, reportee.timeSheetApprover)} />
                                                        <span className="checkmark-R"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="mt-0" />
                                    {
                                        reportee.reporteeTimesheetInfos.length > 0 ?
                                            reportee.reporteeTimesheetInfos.map((cReportee, index) => (
                                                index > 0 ?
                                                    <div key={cReportee.timesheetTagId + index}>
                                                        <div className="row mt-1">

                                                            <div className="col-12 col-md-auto mb-2 mt-2 d-none d-md-block">
                                                                <span className="font-weight-bold">{cReportee.durationStart + " - " + cReportee.durationEnd}</span>
                                                            </div>

                                                            <div className="col-12 mb-2 mt-2 d-block d-md-none">

                                                                <span className="font-weight-bold">{cReportee.durationStart + " - " + cReportee.durationEnd}
                                                                    <a href="#" className="icon-clr-blue ml-3 mt-2" onClick={() => this.redirectToDetailsPage(cReportee, reportee.reporteeName, dataItem)}>
                                                                        <FontAwesomeIcon icon={faFileAlt} className={"lead"} />
                                                                    </a>
                                                                </span>
                                                                <span className="grn-bg-icon-checkbox ml-5">
                                                                    <label className="container-R d-flex col-12">
                                                                        <input type="checkbox" checked={cReportee.isApproveSelected}
                                                                            onChange={(event) =>
                                                                                check(event, dataItem, reportee.reporteeName, cReportee.timesheetTagId, cReportee.durationStart, reportee.timeSheetApprover)} />
                                                                        <span className="checkmark-R gridCheckbox"></span>
                                                                    </label>
                                                                </span>

                                                            </div>
                                                            <div className="col-md-auto ml-md-auto mt-1 text-right">
                                                                <div className="row ml-0 mr-0">
                                                                    <div className="mr-2 d-none d-md-block">
                                                                        <span className="grn-bg-icon just mr-2">
                                                                            <FontAwesomeIcon icon={faClock} />
                                                                        </span>
                                                                        {cReportee.totalHrs}
                                                                    </div>
                                                                    <div className="d-none d-md-block">
                                                                        <span className="grn-bg-icon just mr-2">
                                                                            <FontAwesomeIcon icon={faDollarSign} />
                                                                        </span>
                                                                        {numberFormatter.format(cReportee.billingAmt)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-1">
                                                            <div className="col-md-auto ">
                                                                <table className="table table-condensed table-responsive">
                                                                    <thead>
                                                                        <tr className="txt-clr-blue font-weight-bold">
                                                                            {cReportee.dayInfos.map((dayInfo, index) => (
                                                                                <th key={index} >{dayInfo.dayName}</th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr className="font-weight-bold">
                                                                            {cReportee.dayInfos.map((dayInfo, index) => (
                                                                                <td key={index} >{dayInfo.dayWorkingHrs}</td>
                                                                            ))}
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                                <div className="col-12  mt-1 font-weight-bold pl-0 pr-0">
                                                                    <div className="row ml-0 mr-0">
                                                                        <div className="col-6 d-block d-md-none pl-0">
                                                                            <span className="grn-bg-icon just mr-2">
                                                                                <FontAwesomeIcon icon={faClock} />
                                                                            </span>
                                                                            <span>{cReportee.totalHrs}</span>
                                                                        </div>
                                                                        <div className="col-6 d-block d-md-none text-right pr-0">
                                                                            <span className="grn-bg-icon just mr-2">
                                                                                <FontAwesomeIcon icon={faDollarSign} />
                                                                            </span>
                                                                            <span>{numberFormatter.format(cReportee.billingAmt)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-auto d-flex mt-1 text-right ml-md-auto">
                                                                <div className="ml-4 d-none d-md-block">
                                                                    <a href="#" className="icon-clr-blue ml-3 mt-2" onClick={() => this.redirectToDetailsPage(cReportee, reportee.reporteeName, dataItem)}>
                                                                        <FontAwesomeIcon icon={faFileAlt} className={"lead"} /></a>
                                                                </div>
                                                                <div className=" d-none d-md-block">
                                                                    <label className="container-R d-flex col-12">
                                                                        <input type="checkbox" checked={cReportee.isApproveSelected}
                                                                            onChange={(event) => check(event, dataItem, reportee.reporteeName, cReportee.timesheetTagId, cReportee.durationStart, reportee.timeSheetApprover)} />
                                                                        <span className="checkmark-R"></span>
                                                                    </label>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <hr className="mt-0" />

                                                    </div> : "")) : ""
                                    }

                                    <p className="mb-2">
                                        <span className="mobileBlock">Approver(s): </span>
                                        <span className="font-weight-bold">{reportee.timeSheetApprover}</span>
                                    </p>
                                </div>
                            </div>
                        })
                    }
                </td>

            );
        }

        private redirectToDetailsPage(reporteeObj: ReporteeTimesheetInfo, reporteeName, position: PositionState) {
            event.preventDefault();
            history.push({
                pathname: '/TimesheetDetails',
                state: {
                    jobId: reporteeObj.timesheetTagId,
                    durationStart: reporteeObj.durationStart,
                    durationEnd: reporteeObj.durationEnd,
                    reportee: reporteeName,
                    requestId: reporteeObj.requestId,
                    vendor: position.vendorName,
                    position: position.position,
                    division: position.division,
                    deptLocation: position.deptLocation,
                    vendorId: position.vendorID,
                    jobTypeId:position.jobTypeId,
                    clientId:position.clientId
                }
            });
        }
    }
}


