import React from "react";
import { IClientRateCardState } from "./IClientRateCardState";
import { formatter, authHeader } from '../../HelperMethods';
import * as GlobalStore from '../../store/Global';
import { RouteComponentProps, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import ShimmerTab from "../Shared/ShimmerTab";

export type GlobalProps = GlobalStore.GlobalState
    & typeof GlobalStore.actionCreators
    & RouteComponentProps<{ location: any }>;

export class ClientRateCard extends React.Component<GlobalProps, IClientRateCardState> {
    private previousState = this.props.location.state;
    constructor(props) {
        super(props);

        this.state = {
            billRateList: [],
            dataLoaded: false,
            overtimeRate: 1.0
        }
    }

    public render() {
        return (
            <div>
                <div className="row mt-3 ml-0 mr-0 ">
                    <div className="col-12 pl-0 pr-0 mx-auto">
                        <div className="row font-medium">
                            <div className="col-6 col-lg-auto">
                                <p className="font-weight-normal">Position: <span className="font-weight-bold Charcoal-blck"> {this.previousState.position}
                                </span></p>
                                <p className="font-weight-normal">Division: <span className="font-weight-bold Charcoal-blck"> {this.previousState.division}
                                </span></p>
                            </div>
                            <div className="col-6 col-lg-auto ml-auto text-right">
                                <p>Dept/Location:<span className="font-weight-bold Charcoal-blck"> {this.previousState.deptLocation}</span></p>
                                <p>Overtime Rate:<span className="font-weight-bold Charcoal-blck"> {this.state.overtimeRate} </span></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-1">
                    <div className="col-12">
                        <hr />
                    </div>
                </div>
                <div className="col-12 mx-auto text-center mt-1">
                    <div style={{ display: this.state.dataLoaded ? "none" : "block" }}><ShimmerTab /></div>
                </div>

                <div className="row mt-0" style={{ display: this.state.dataLoaded ? "block" : "none" }}>
                    <div className="col-12">
                        <div className="shadow">
                            <div className="table-responsive">
                                <table className="custom-tbl table table-striped">
                                    <thead>
                                        <tr className="th">
                                            <th>Service Type</th>
                                            <th>Bill Type</th>
                                            <th className="text-right">Min Rate</th>
                                            <th className="text-right">Max Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.billRateList.length > 0 ?
                                            this.state.billRateList.map((bill, index) => (
                                                <tr key={index}>
                                                    <td className="txt-clr-blue font-weight-bold">{bill.serviceType}</td>
                                                    <td >{bill.billType}</td>
                                                    <td className="text-right">{formatter.format(bill.minRate)}</td>
                                                    <td className="text-right">{formatter.format(bill.maxRate)}</td>
                                                </tr>
                                            ))
                                            :
                                            <tr className="text-center">
                                                <td colSpan={4}>No data to display</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public async componentDidMount() {
        var pageTitleElem = document.getElementById("curPageTitle");
        pageTitleElem.innerText = "Client Rate Card";

        //this.props.updatePageName("Client Rate Card");
    }

    public componentWillMount() {
        this.getClientBillRateCards();
    }

    public getClientBillRateCards() {
        var requestBody = {
            JobTypeId: this.previousState.jobTypeId,// 'ANES-UB4Y1UXJYJ',
            ClientId: this.previousState.clientId, //'NAPA-952F32JMWH'
            ClientDivisionId: this.previousState.clientDivisionId,
            ClientLocationId: this.previousState.clientLocationId,
            VendorId: this.previousState.vendorId
        };

        var requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
        };

        fetch(`/api/Timesheet/GetBillRateCards`, requestOptions).then(function (response) {
            return response.json();
        })
            .then(detailData => {
                this.setState({
                    billRateList: detailData,
                    dataLoaded:true
                });

                if(detailData.length > 0){
                    this.setState({
                        overtimeRate : detailData[0].overtimeRate ? detailData[0].overtimeRate : 1
                    });
                }
                // console.log(detailData);
            });
    }
};

export default connect(
    (state: ApplicationState) => state.global, // Selects which state properties are merged into the component's props
    GlobalStore.actionCreators // Selects which action creators are merged into the component's props
)(ClientRateCard as any); 