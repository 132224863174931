import React, { Component } from 'react';
import { ILoginState } from './ILoginState';
import { history, GlobalProps } from '../../HelperMethods';
import './LoginStyles.css';
import { ApplicationState } from '../../store';
import * as GlobalStore from '../../store/Global';
import { connect } from 'react-redux';

const loadingPanel = (
    <div className="k-loading-mask">
        <span className="k-loading-text">Loading</span>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
    </div>
);

export class Login extends Component<GlobalProps, ILoginState> {

    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            password: '',
            submitted: false,
            errorMessage: '',
            rememberMe: true
        }

        // localStorage.removeItem('user');
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e, modelProp) {
        var stateObj = {};
        stateObj[modelProp] = e.target.type == "checkbox" ? e.target.checked : e.target.value;
        this.setState(stateObj);
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.password != '' && this.state.userName != '') {
            this.loginUser();
        }
        else {
            this.setState({
                errorMessage: "Please fill in the mandatory data before submitting."
            });
        }
    }
    render() {
        return (
            <div>
                {this.state.submitted && loadingPanel}
                <div className="d-flex justify-content-center">
                    <div className="shadow-login mt-2">
                        <div className="row mt-2 ml-0 mr-0" >
                            <div className="col-12 d-flex justify-content-center mt-3">
                                <img className="ringo-logo" src={require("../../assets/images/ringo_login.png")} alt="logo" />
                            </div>
                        </div>
                        <form>
                            <div className="row ml-0 mr-0 ">
                                <div className="relative">
                                    <div className="row justify-content-center ml-0 mr-0">
                                        <h5 className="col-11 col-md-11 text-center font-weight-normal pt-5">Sign In
						                <p className="font-medium mt-4 font-weight-normal">Welcome to RINGO</p>
                                        </h5>
                                    </div>
                                    <div className="row ml-0 mr-0 mt-3 justify-content-center">
                                        <div className="col-10 col-md-10">
                                            <label className="mb-1 required text-light">Username</label>
                                            <input type="text" required
                                                className="form-control font-medium text-light placeholder placeholder-brd"
                                                value={this.state.userName} placeholder="Enter Your Username" onChange={(e) => this.handleChange(e, 'userName')} />
                                        </div>
                                        <div className="col-10 col-md-10">
                                            <label className="mt-3 mb-1 required text-light">Password</label>
                                            <input type="password" required
                                                className="form-control font-medium text-light placeholder placeholder-brd"
                                                value={this.state.password} placeholder="Enter Your Password" onChange={(e) => this.handleChange(e, 'password')} />
                                        </div>
                                        <div className="col-10 col-md-10 ml-0 mr-0 mt-2 ml-2 d-block d-md-none">
                                            <label className="container-R d-flex">
                                                <input type="checkbox" className="mr-3" checked={this.state.rememberMe} onChange={(e) => this.handleChange(e, 'rememberMe')} />
                                                <span className="ml-1 font-medium">Remember me</span>
                                                <span className="checkmark-R rememberMeCheck"></span>
                                            </label>
                                        </div>

                                        <div className="col-10 col-md-10 ml-0 mr-0 mt-2 ml-3 d-none d-md-block">
                                            <label className="container-R d-flex">
                                                <input type="checkbox" className="mr-3" checked={this.state.rememberMe} onChange={(e) => this.handleChange(e, 'rememberMe')} />
                                                <span className="ml-1 font-medium">Remember me</span>
                                                <span className="checkmark-R check"></span>
                                            </label>
                                        </div>
                                        <div className="col-10 col-md-10 ml-0 mr-0 text-center justify-content-center align-items-center mt-5">
                                            <button type="submit" className="btn button button-signin font-weight-bold mb-2" onClick={this.handleSubmit}>Sign In
						                </button>
                                            {this.state.errorMessage && <span className="errorMessage">{this.state.errorMessage}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className="ml-0 mr-0 text-center d-block d-md-none font-medium txt-dar-blue">Copyright {new Date().getFullYear()}, All rights reserved by Ringo</div>
                        <div className="ml-0 mr-0 mb-2 text-center d-none d-md-block font-medium txt-dar-blue">Copyright {new Date().getFullYear()}, All rights reserved by Ringo</div>
                    </div>
                </div>
            </div>
        );
    }

    public componentWillMount() {
        document.title = "Ringo - Login";
        if (localStorage.getItem('user')) {
            history.push('/ApproveTimesheet');
        }
    }

    private loginUser() {
        this.setState({
            submitted: true
        });
        var requestBody = {
            "UserName": this.state.userName,
            "Password": this.state.password
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
        };

        fetch(`/api/Account/Authenticate`, requestOptions).then(function (response) {
            return response.json();
        }).then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (user.isUserValid) {
                var userPayLoad: GlobalStore.GlobalState = {
                    CurrentPageName: "Submitted Timesheets",
                    LoginUserName: user.userFullName,
                    UserClientName: user.clientName
                };
                this.props.updateUserDetails(userPayLoad);
                localStorage.setItem('user', JSON.stringify(user));
                history.push('/ApproveTimesheet');
                //console.log(user);
            }
            else {
                this.setState({
                    submitted: false,
                    errorMessage: user.errorMessage
                });
            }
        },
            error => {
                this.setState({
                    submitted: false
                });
                alert(error.errorMessage);
            }

        );
    }


}

export default connect(
    (state: ApplicationState) => state.global, // Selects which state properties are merged into the component's props
    GlobalStore.actionCreators // Selects which action creators are merged into the component's props
)(Login as any);