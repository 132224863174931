import * as React from 'react';
import { Router } from 'react-router';
import './custom.css';
import { history } from '../src/HelperMethods';
import Routes  from '../src/components/Routes';

export class App extends React.Component {
    constructor(props) {
        super(props);
        // localStorage.removeItem('user');
    }

    render() {
        return (
            <Router history={history}>
               <Routes />
            </Router>
        );
    }

    public componentWillMount(){
        if (localStorage.getItem('user')) {
            var pathname = window.location.pathname;
            if(pathname == '/'){
                history.push('/ApproveTimesheet');
            }
        }
        else {
            history.push('/Login');
        }
    }

    
}



export default App;
