import { createBrowserHistory } from 'history';
import * as GlobalStore from '../src/store/Global';
import { RouteComponentProps } from 'react-router-dom';

export const history = createBrowserHistory();

export function authHeader() {
    // return authorization header with jwt token
    var userString = localStorage.getItem('user');
    if (userString) {
        let user = JSON.parse(userString);
        return { 'Authorization': 'Bearer ' + user.token };
    }
    else {
        return { 'Authorization': 'Bearer xadas' };
    }
}

export function logout() {
    localStorage.removeItem('user');
    window.location.reload(true);
}

export type GlobalProps = GlobalStore.GlobalState
    & typeof GlobalStore.actionCreators
    & RouteComponentProps<{}>;


export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2, 
    minimumFractionDigits: 2, 
})


export const numberFormatter = new Intl.NumberFormat('en-IN', 
{ 
    maximumFractionDigits: 2, 
    minimumFractionDigits: 2  });