import NavMenu from "../NavMenu";
import * as React from "react";

interface IProps {
    children?: React.ReactNode;
}

type Props = IProps;

export default class AuthorizedLayout extends React.Component<Props, {}> {
    public render() {

        return <div id="authorizedLayout" className="layout">
            <NavMenu />
            <div className="container container-content">
                {this.props.children}
            </div>
        </div>;
    }
}