import AuthorizedLayout from '../components/Layouts/AuthorizedLayout';
import GuestLayout from "../components/Layouts/GuestLayout";
import LoginPage from '../components/Login/Login';
import { AppRoute } from "../components/Shared/AppRoute";
import * as React from 'react';
import { Switch } from 'react-router-dom';
import { ApproveTimesheet } from '../components/ApproveTimesheet/ApproveTimesheet';
import TimesheetDetails from '../components/TimesheetDetails/TimesheetDetails';
import { ClientRateCard } from '../components/ClientRateCard/ClientRateCard';
import { ProviderContact} from '../components/ProviderContact/ProviderContact';

interface IProps {
    children: any;
}

export default class Routes extends React.Component<{}, {}> {
    public render() {

        return <Switch>
            <AppRoute layout={GuestLayout} exact path="/login" component={LoginPage} />
            <AppRoute layout={AuthorizedLayout} exact path="/ApproveTimesheet" component={ApproveTimesheet} />
            <AppRoute layout={AuthorizedLayout} exact path="/TimesheetDetails" component={TimesheetDetails} />
            <AppRoute layout={AuthorizedLayout} exact path="/ClientRateCard" component={ClientRateCard} />
            <AppRoute layout={AuthorizedLayout} exact path="/ProviderContact" component={ProviderContact} />
        </Switch>;
    }
}